import * as BaseIcons from '@doist/icons/base'
import * as BrandIcons from '@doist/icons/brand'

export const doistIcons = {
    brand: {
        Plus: BrandIcons.Plus,
        ChevronRight: BrandIcons.ChevronRight,
        ChevronUp: BrandIcons.ChevronUp,
        ChevronDown: BrandIcons.ChevronDown,
        ExternalLink: BrandIcons.ExternalLink,
        Article: BrandIcons.Article,
        X: BrandIcons.X,
        Check: BrandIcons.Check,
        Warning: BrandIcons.Warning,
        ArrowRight: BrandIcons.ArrowRight,
        SearchBase: BrandIcons.SearchBase,
        DoubleArrows: BrandIcons.DoubleArrows,
        Globe: BrandIcons.Globe,
        Menu: BrandIcons.Menu,
    },
    base: {
        Article: BaseIcons.Article,
        ChevronRight: BaseIcons.ChevronRight,
        ChevronLeft: BaseIcons.ChevronLeft,
        ChevronDown: BaseIcons.ChevronDown,
        TaskView: BaseIcons.TaskView,
        Lightbulb: BaseIcons.Lightbulb,
        Doist: BaseIcons.Doist,
        SocialFacebook: BaseIcons.SocialFacebook,
        SocialInstagram: BaseIcons.SocialInstagram,
        SocialLinkedin: BaseIcons.SocialLinkedin,
        SocialTwitter: BaseIcons.SocialTwitter,
        SocialYoutube: BaseIcons.SocialYoutube,
        RSS: BaseIcons.RSS,
        Warning: BaseIcons.Warning,
        ArrowRight: BaseIcons.ArrowRight,
        Download: BaseIcons.Download,
        NewTab: BaseIcons.NewTab,
        Play: BaseIcons.Play,
        Close: BaseIcons.Close,
        Checkmark: BaseIcons.Checkmark,
        TwistUpload: BaseIcons.TwistUpload,
        UploadDoc: BaseIcons.UploadDoc,
        UploadImage: BaseIcons.UploadImage,
        UploadPDF: BaseIcons.UploadPDF,
        UploadVideo: BaseIcons.UploadVideo,
    },
    android: {},
    ios: {},
    web: {},
}
